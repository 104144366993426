:root {
    --color-primary: #000000;
    --color-secondary: #222222;
    --color-tertiary: #303030;
    --color-text: #383838;
    --color-buttons: #f7d23e;

}


.signup__container {
    background-image: linear-gradient(rgba(0, 0, 0, 0.699), rgba(8, 8, 8, 0.158), rgba(0, 0, 0, 0.671)), url('../images/custom/landing-landscape.jpg');
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    display: flex;
    height: 100vh;
    display: flex;
    width: 100%;

}


.signup__container-inner {
    border: black solid 1px;
    padding: 2rem;
}

.signup__heading {
    margin-bottom: 1rem;
}

.signup__text {
    margin-top: 1vh;
}

.signup-button {
    margin-top: 1.5rem;
}

.signup__anchor-tag {
    color: var(--color-buttons);
    cursor: pointer;
}

.error-text {
    margin: 0px !important;
    margin-left: 15px !important;
}

.custom-field {
    margin-bottom: 0px !important;
}

.signup-inner {
    width: fit-content !important;
}

@media (max-width: 600px) {}
