:root {
  --color-primary: #000000;
  --color-secondary: #222222;
  --color-tertiary: #303030;
  --color-text: #383838;
  --color-buttons: #f7d23e;

}

.get-now-btn-txt {
  font-size: 1vw;
  color: #000000;
}

.os-icon {
  color: #000;
}

.landing-logo {
  width: 20rem;
  height: 100%;
  object-fit: cover;
}

.hero__section__container {
  min-height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.699), rgba(8, 8, 8, 0.158), rgba(0, 0, 0, 0.671)), url('../../images/custom/landing-landscape.jpg');
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  padding-inline: 3rem;
  flex-wrap: wrap;
}

.navbar--logo-img {
  height: 20vh;
  width: 14vw;

}

.navbar--button {
  background-color: var(--color-buttons);
  padding-inline: 1rem;
  padding-block: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  letter-spacing: 1px;
  cursor: pointer;
}

.navbar--button :hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

}

.navbar--button :focus {
  border: none;
}

.navbar--button :active {
  border: none;
}

.hero__section--text {
  color: white;
  font-size: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85vh;
  align-items: center;
  text-align: center;
}

.hero__section--text-header {
  font-size: 4vw;
  margin-bottom: 20px;
}

.hero__section--text-tertiary {
  font-size: 1vw;
  margin-bottom: 5px;
  text-align: center;
}

.hero__section__search {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 35vw;
  flex-direction: row;
}

.hero__section-input {
  font-size: 0.8vw;
  display: flex;
  flex: 1;
  height: 7vh;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  padding-left: 1.2rem;
  border: none;
  border-radius: 8px 0px 0px 8px;
}

.hero__section-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-buttons);
  border: none;
  letter-spacing: 1px;
  font-size: 1vw;
  font-weight: bold;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
}

.hero__section-search-button-text {
  display: block;
  font-size: 0.8vw;
}

.hero__section-search-button-icon {
  font-size: 1.5rem;
  font-weight: bold;
  display: none;
}

.hero__section--text-header-secondary {
  font-size: 2vw;
  margin-bottom: 10px;
}

.gray-line {
  width: 100%;
  height: 0.5vh;
  background-color: var(--color-text);
}

/* //*----------------------mobile Screen------------------------- */

@media (max-width : 600px) {
  .get-now-btn-txt {
    font-size: 3.5vw;
  }

  .landing-logo {
    width: 14rem;
  }

  .hero__section-input {
    background-color: #262525;
    height: 2vh;
    font-size: 14px;
  }

  .hero__section-search-button-text {
    display: none;
  }

  .hero__section-search-button-icon {
    display: block;
  }

  .hero__section__search {
    width: 80vw;
  }

  .hero__section--text-tertiary {
    font-size: 4vw;
  }

  .hero__section__container {
    height: 60vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.699), rgba(8, 8, 8, 0.258), rgba(0, 0, 0, 0.671)), url('../../images/custom/landing-portrait.jpg');
  }

  .navbar {
    padding-inline: 1.5rem;
  }

  .navbar--logo-img {
    height: 20vh;
    width: 32vw;
  }

  .navbar--button {
    padding-inline: 0.5rem;
    padding-block: 0.3rem;
    font-size: 0.8rem;
    border: none;
    border-radius: 3px;
    letter-spacing: 1px;
  }

  .hero__section--text {
    font-size: 5vw;
    justify-content: center;
  }

  .hero__section--text-header {
    font-size: 8.5vw;
  }

  .hero__section--text-header-secondary {
    font-size: 5vw;
    font-weight: bold;
  }
}

/* ----------------------------------------------4k screen-------------------------------------------- */
@media (min-width: 2560px) and (max-width: 2560px) {

  .hero__section__container {
    height: 100vh;
    background-size: cover;
  }

  .navbar {
    padding-inline: 2.5rem;
  }

  .navbar--logo-img {
    height: 20vh;
    width: 20vw;
  }

  .hero__section--text {
    font-size: 2vw;
    justify-content: center;
  }


  .hero__section--text-header {
    font-size: 4w;
  }

  .hero__section--text-header-secondary {
    font-size: 2vw;
  }

  .gray-line {
    width: 100%;
    height: 0.5vh;
    background-color: var(--color-text);
  }
}