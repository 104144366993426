
:root {
    --color-primary: #000000;
    --color-secondary: #222222; 
    --color-tertiary: #303030;
    --color-text: #383838;
    --color-buttons: #f7d23e;

  }


.footer__container{
    display: flex;
    height: 30vh;
    background-color: black;
    flex-wrap: wrap;

}

.footer__list{
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    list-style: none;
    color: white;
    flex-wrap: wrap;
    font-size: 1.2vw;
}

.footer__social-icons{
    list-style: none;
   font-size: 1.5vw;

}


.footer__container-inner{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-center{
    color: white;
    font-size: 1.2vw;
}

.footer__social-icons{
    display: flex;
   justify-content: space-between;
   align-items: center;
  
   flex-wrap: wrap;

}

a{
    color: var(--color-buttons);
}


a:hover{
    color: var(--color-buttons);
}

.text-primary{
    color: yellow;
}


@media (max-width: 600px) {

    
.footer__container{

    height: 20vh;

}
    .footer__list{
        font-size: 4vw;
    }
    .footer__social-icons{
  
       font-size: 4vw;
    
    }

    .text-center{

        font-size: 3vw;
    }
    
}


@media (max-width: 2560px) and (min-width: 2560px) {

    
    .footer__container{
    
        height: 22vh;
    
    }
        .footer__list{
            font-size: 2vw;
        }
        .footer__social-icons{
      
           font-size: 2vw;
        
        }
    
        .text-center{
    
            font-size: 1.5vw;
        }
        
    }