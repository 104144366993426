.notification-card-body {
    max-height: 70vh;
}

.custom-card {
    padding: 8px !important;
    padding-left: 3px !important;
}

.notification-date {
    display: block;
    margin-top: 3px;
}

.notification-read {
    color: #FFCA00;
}

.notification-read:hover {
    text-decoration: underline;
}

.notification-wrapper {
    display: flex;
    flex-direction: row;
    border-top: 0.2px solid gray;
}

.last-notification-wrapper {
    /* border-bottom: 0.5px solid gray; */
}

.unread-bar {
    display: flex;
    flex: 1 1;
    width: 5px;
    border: 1px solid #FFCA00;
    margin-top: 1.5px;
    margin-bottom: 2px;
}

.read-bar {
    display: flex;
    flex: 1 1;
    width: 5px;
    border: 1px solid #212020;
    margin-top: 1.5px;
    margin-bottom: 2px;
}

.notifications-header {
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
}

.notifications-title {
    font-size: 18px;
    font-weight: bold;
}

.read-all-btn {
    font-size: 12px;
    font-weight: bold;
    color: #FFCA00;
    cursor: pointer;
}

.read-all-btn:hover {
    text-decoration: underline;
}