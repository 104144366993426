/*---------------------------------Variables -----------------------*/

:root {
    --color-primary: #fae17e;
    --color-sacondary: #ffca00;
    --color-tertiary: #6c757d;
}

body {
    font-size: 1vw;
}

.red-border {
    border: 0px solid red;
}

.yellow-border {
    border: 0px solid yellow;
}

.home-banner {
    height: 100vh;
}

.banner-video-player {
    height: 100vh !important;
    width: 98.7vw !important;
    position: absolute;
    top: 0;
    object-fit: fill;
}

.banner-video-player video {
    object-fit: cover;
}

.banner-video-player button {
    display: none;
}

.season-banner-tile {
    /* padding: 10px; */
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 15px;
    margin-right: 15px;
    background-color: #ffca0055;
    margin-top: 20px;
}

.banner-season-img {
    width: 8vw;
    height: 15vh;
    object-fit: cover;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.season-banner-right-col {
    padding: 0px;
}

.season-banner-tile-left {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 10px;
}

.banner-season-desc {
    color: white;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.channel-logo,
.navbar ul li .sub-menu li>a::after,
.iq-blog-meta ul li,
.widget-container p {
    background: transparent linear-gradient(270deg, rgba(11, 1, 2, 0) 0%, rgba(255, 202, 0, 0.3) 100%);
}

.nav-pills .nav-link.active {
    background: transparent linear-gradient(360deg, rgba(11, 1, 2, 0) 0%, rgba(255, 202, 0, 0.3) 100%) !important;
}

.slider-title-wrapper,
.slider-section {
    margin-top: 0px !important;
}

.custom-container {
    padding: 0px 25px;
}

.custom-navbar {
    height: 60px;
}

#back-to-top {
    position: relative;
    z-index: 998;
}

.custom-img-box {
    height: 10rem;
}

.bottom-shows-container .custom-img-box {
    max-height: 10rem;
    /* height: 10rem; */
}

.custom-swap-slide {
    max-height: 8rem;
}

#parallex {
    margin-top: 10px;
}

.cat-cast-div {
    margin: 30px 0px;
}

.font-small,
.text-body {
    font-size: 0.9vw !important;
}

.font-xsmall,
.text-body {
    font-size: 0.8vw !important;
}

.trending-shows-div {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
}

.trending-shows-1st-tile {
    display: flex;
    flex: 2;
    /* height: 10vh; */
}

.trending-shows-tile {
    display: flex;
    flex: 1;
    /* height: 10vh; */
}

.custom-icon {
    font-size: 20px !important;
    margin-top: 2px;
    margin-right: 2px;
}


.share-box {
    width: 70px;
    border-radius: 5px;
}

.share-box .align-items-center {
    justify-content: space-around;
}

.hover-icon {
    cursor: pointer;
}

.modal-container {
    background-color: black;
    border-radius: 30px;
}

.modal-body {
    background-color: #181818;
}

/*--------------------------------------------------------Cards---------------------*/
.seasons-card-container {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.favorites-slider li.slide-item {
    width: 15vw;
}
.details-swiper-container{
    height: 300px;
}
.main-swiper-slides{
    overflow: hidden;
    border-radius: 5%;
}
.slide-item .details-swiper{
    height: 100%;
}
.block-images1 .img-box {
    height: 100%;
    width: 100%;
}
/* Ensure the image takes up the full height and width of its container */
/* .block-images1 .img-box img {
    height: 100%;
    width: 100%;
  } */
.img-box-horizontal {
    height: 24vh !important;
    width: 19rem !important;
}

.popular-seasons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 25vw !important;
}

.vertial-cards-div .img-box {
    height: 41vh !important;
    background-size: cover !important;
}

.img-fluid {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.main-title {
    font-size: 22px;
}

.main-cont {
    border: red dotted;
}

.separate-container {
    height: 41vh;
    margin-left: 0.5rem;
    margin: 0px;
}

.vertical-card-img {
    width: 100%;
}

.card-container {
    width: 90% !important;
}

.text-xlarge {
    font-size: 1.2vw;
}

.text-large {
    font-size: 1vw !important;
}

.text-medium {
    font-size: 0.8vw !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    display: none;
}

@media only screen and (min-width: 2000px) {
    .custom-img-box {
        height: 12rem;
    }

    .custom-swap-slide {
        max-height: 10rem;
    }
}

@media only screen and (min-width: 2500px) {
    .custom-img-box {
        height: 14rem;
    }

    .custom-swap-slide {
        max-height: 12rem;
    }
}