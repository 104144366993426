
:root {
    --color-primary: #000000;
    --color-secondary: #222222; 
    --color-tertiary: #303030;
    --color-text: #383838;
    --color-buttons: #f7d23e;

  }


.main__screen__container{
    min-height: 95vh;
    background-color:#000000 ;
}

