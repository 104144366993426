:root {
    --color-primary: #000000;
    --color-secondary: #222222;
    --color-tertiary: #303030;
    --color-text: #383838;
    --color-buttons: #f7d23e;

}

.faq-question,
.faq-answer {
    font-size: 0.8vw !important;
}

.faq-icon {
    font-size: 1.2vw !important;
}

.faqs__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faq-header {
    padding: 0;
    margin: 0;
}

.faq-header {
    color: white;
    padding: 1.8rem;
    font-size: 3vw;

}

.questions-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.questions-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.quetion {
    color: white;
    background-color: var(--color-text);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    padding-block: 1.3vh;
    padding-left: 1rem;
    width: 45vw;
    margin: 10px;
    font-size: 2vw;
}

.faq-text {
    color: white;
    margin-top: 2rem;
    font-size: 1.8vw;
}

.add-sign {
    color: white;
    font-size: 1.5vw;
    display: flex;
    align-items: center;
    margin-right: 1.2vw;
}

.faq-accordion-container {
    width: 50%;
    margin: 0 auto;
}

@media (max-width: 425px) {

    .faq-question,
    .faq-answer {
        font-size: 3vw !important;
    }

    .faq-icon {
        font-size: 4vw !important;
    }

    .faq-header {
        font-size: 5.5vw;
    }

    .faq-text {
        font-size: 5.3vw;
    }

    .faq-accordion-container {
        width: 100%;
    }

}

@media (min-width: 425px) and (max-width: 768px) {

    .faq-question,
    .faq-answer {
        font-size: 1.8vw !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {

    .faq-question,
    .faq-answer {
        font-size: 1.2vw !important;
    }
}

@media (min-width: 2560px) {

    .faq-question,
    .faq-answer {
        font-size: 0.8vw !important;
    }

    .faq-header {
        font-size: 4vw;
    }

    .faq-text {
        font-size: 2vw;
    }

    .faq-accordion-container {
        width: 50%;
        height: 60%;

    }

    .faq-accordion {
        width: 100%;

    }

    .accordion-text {
        font-size: 2vw;
    }
}