.new-episode-video-player {
    /* height: 100% !important; */
    width: 100% !important;
    height: 55vh !important;
}

.new-episode-video-player video {
    object-fit: cover;
}

.new-episode-video-player button {
    display: none;
}

.player-wrapper {
    position: relative;
}

.new-episode-tile .play-icon {
    font-size: 100px;
    color: #FFCA00;
    cursor: pointer;
}

.new-episode-tile .controlls-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.heading-container {
    margin: 40px 0px !important;
}

.main-heading {
    font-size: 1.6vw !important;
}

.new-episode-tile {
    cursor: pointer;
    display: flex;
    height: 40vh;
    flex-direction: column;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;

}

.new-episode-tile .top-div {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.new-episode-tile .bottom-div {
    flex: 1;
    background-color: #181818;
    padding: 15px;
    padding-top: 5px;
    height: 20vh;
    cursor: default;
}

.episode-name-date {
    display: flex;
    flex-direction: row;
}

.new-episode-tile .episode-image {
    width: 100%;
    object-fit: cover;
}

.new-episode-tile .episode-name {
    font-size: 1vw;
}

.new-episode-tile .episode-date {
    font-size: 1vw;
}

.new-episode-tile .episode-desc {
    padding-top: 5px;
    font-size: 0.9vw;
}
.new-ep-slides{
    height: 40vh;
}

.share-icons{
    position: absolute;
    right: 0;
    top: 0;
}

.play-new-ep{
    position: absolute;
    padding-bottom: 1rem;
}