/*---------------------------Modal-------------------------------*/

.modal-show-desc {
    /* text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical; */
    font-size: 0.9rem;
}

.modal-show-cast {
    font-size: 0.9rem;
}

.ShowMore_DefaultButton__GW5Eb {
    background: #92702a !important;
    padding: 0px 2px !important;
    width: auto !important;
    max-width: 250px;
    border: none !important;
    transition: all 0.2s;
    border-radius: 8px !important;
}

.ShowMore_DefaultButton__GW5Eb:hover {
    background-color: #ffca00 !important;
}

.ShowMore_ButtonDiv__3ugHS {

    justify-content: flex-end !important;
    align-items: flex-end !important;
    padding: 0px !important;
}


.ShowMore_ShowMore__2o_dX {
    background-color: transparent !important;
}

/* .modal-div{
    background-color: black;
  } */



.season-details-header {
    color: black;
    font-size: 3vh;
}

.nav-item .nav-icon {
    font-size: 4vh;
}


.season-details-header_paragraph {
    font-size: 1.9vh;
}

.modal-video-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    width: 4rem;
    background-color: rgba(233, 230, 71, 0.8);
    position: absolute;

    border-radius: 50%;
    cursor: pointer;
}

.modal-div {
    color: white;
    border-radius: 30px;
}

.modal-content {
    background-color: rgb(46, 44, 44);
    color: white;
}

.ml-3 {
    color: white;
    margin-left: 80px;
}

.season-button {
    /* justify-content: center;
    align-items: flex-start; */
    gap: 10px;
}

.p-tag {
    margin-bottom: 0rem !important;

    margin-top: 0.4rem !important;
}

.modal-container {
    background-color: black;
    border-radius: 30px;
}

.text-align-right {
    display: flex;
    flex-direction: column;

    flex-wrap: wrap;

}

.ml-3 {
    margin-right: 3px;
    color: white;
}

.modal-text {
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.show-detail-modal .text-left {
    width: 400px;
}

.span-props {
    text-transform: uppercase;
    color: var(--color-sacondary);
    font: sans-serif 0.5rem;
    margin-right: 10px;
}

.episodes-heading {
    margin: 1rem;
    color: white;
}

.season-episodes {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 10px;
}

.season-episodes-upper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: white;
    gap: 1rem;
}

.video-placement {
    height: 100%;
    width: 100%;
}

.episode-heading {
    align-self: flex-start;
    color: white;
}

.seasons-episode-img {
    display: flex;
    width: 12vw;
    height: 15vh;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.modal-video {
    /* object-fit: contain; */
    background-size: cover;
    width: 100%;

}

.span-tags {
    margin-top: 1rem;
}

.show-detail-modal .span-tags {
    font-size: 0.8rem;
}

.episode-card-wide {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin-bottom: 15px;
    margin-top: 15px;
    height: 90px;
}

.episode-image {
    height: 100%;
    width: 8vw;
    object-fit: cover;
    border-radius: 5px;
    background-color: gray;
}

.episode-content {
    flex: 1;
    flex-direction: column;
    padding-left: 1rem;
}

.episode-content-top {
    display: flex;
    flex-direction: row;
}

.episode-name-div {
    flex: 1;
}

.episode-name {
    font-size: 1.2rem;
    font-weight: bold;
}

.episode-date-div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.episode-content-bottom,
.episode-content-top {
    flex: 1,
}

.episode-content-bottom {
    padding-top: 0.5rem;
}

.episode-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: normal;
}

.episode-date {
    font-weight: normal;
    font-size: 0.8rem;
}