:root {
    --color-primary: #000000;
    --color-secondary: #222222;
    --color-tertiary: #303030;
    --color-text: #383838;
    --color-buttons: #f7d23e;

}

.contact-us-container {
    background-color: black;
    display: flex;
    align-items: center;
    padding: 0px 20px 40px 20px;
}

.ContactUs__container-inner {
    background-color: rgb(46, 45, 45);
    box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
    /* opacity: 0.4; */
    border-radius: 6px;
}

.send-message-button {
    color: black;
    background-color: var(--color-buttons);
    border-radius: 8px;
    border: none;
    letter-spacing: 1px;
    font-weight: bold;
    border-radius: 8px !important;
    cursor: pointer;
}

.send-message-button:hover {
    color: black;
    background-color: var(--color-buttons);
}

.send-message:hover {
    color: black;
    background-color: var(--color-buttons);
}

.form-control {
    border-radius: 8px !important;
}

.form-control:focus {
    border-color: #f7d23e !important;
    box-shadow: none !important;
}

.btn-light:focus {
    box-shadow: none !important;
}

.input-group {
    border-radius: 8px !important;
}

.input-group>.form-control:focus {
    background-color: #000000 !important;
    color: white;
}

@media screen and (max-width: 600px) {
    .send-message-button {
        font-size: 12px;

    }

    .Contactus-header {
        font-size: 20px;
    }
}