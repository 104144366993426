.custom-modal-header {
    height: 50px;
}

.custom-modal-footer {
    height: 50px;
    padding: 0px 10px 0px 0px;
}

.custom-btn {
    margin-top: 0px !important;
}

button.close:hover {
    color: white !important;
}

button.close:focus {
    color: white !important;
}

button.close {
    padding-top: 10px !important;
    color: white;
}

button.close span {
    font-size: 25px;
}

.profile-avatar {
    object-fit: cover;
}

.profile-avatar-small span {
    font-size: 14px;
}

.profile-avatar-small {
    margin-top: 6px;
}

.avatar-edit-container {
    display: flex;
}

.avatar-container {
    margin: 0 auto;
    position: relative;
}

.avatar-file-input {
    display: none;
}

.avatar-file-input-conrainer {
    position: absolute;
    bottom: 20px;
    right: 0;
}

.custom-dropdown {
    height: 52px;
}

.custom-form-controll {
    margin-bottom: 0px !important;
}