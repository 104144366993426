.eyt__container {
    height: 60vh;
    background-color: black;
    display: flex;
    color: white;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

}

.eyt__content-right-text-header {
    font-size: 3.5vw;
}

.eyt__content-right-text {
    font-size: 1.5vw;
}

.eyt__content-right {
    display: flex;
    flex-direction: column;
}

.always-ad-free {
    width: 60%;
    height: auto;
}

/* ------------------------------------------mobile Screen-------------------------------------------------- */
@media (max-width: 600px) {
    .eyt__container {
        height: 60vh;

    }

    .eyt__content-right-text-header {
        font-size: 7vw;
    }

    .eyt__content-right-text {
        font-size: 4vw;
    }
}

/* ---------------------------------------------------4k screen---------------------------------------------------------- */

@media (min-width: 2560px) and (max-width: 2560px) {
    .eyt__content-right-text-header {
        font-size: 3vw;
    }

    .eyt__content-right-text {
        font-size: 2vw;
    }
}

@media only screen and (min-width: 600px) {
    .eyt__content-left {
        width: 18vw;
    }
}

@media only screen and (max-width: 600px) {
    .always-free-container {
        flex: 0 0 45%;
        max-width: 45%;
    }

    .always-ad-free {
        width: 100%;
        height: auto;
    }
}