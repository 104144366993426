.touched-top-header {
    background: transparent linear-gradient(0deg, rgba(11, 1, 2, 0) 0%, rgba(0, 0, 0, 0.65) 100%) !important;
    backdrop-filter: none !important;
}

.not-touched-top-header {
    background: rgba(20, 20, 20, 0.5) !important;
}

.my-header {
    transition: all 0.3s ease-in-out;
}

.category-dropdown {
    background-color: #181818 !important;
    margin-top: 10px !important;
}

.category-dropdown .dropdown-item {
    color: white !important;
}

.dropdown-item:hover {
    color: black !important;
}

.dropdown-toggle::after {
    margin-top: 20px;
}

.notification-badge {
    height: 15px !important;
    width: 15px !important;
    font-size: 12px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    right: -8px !important;
    top: -6px !important;
    color: white;
    padding: 10px !important;
}

.notification-image {
    height: 65px;
    width: 65px;
    border-radius: 10px;
}

.search-box {
    background-color: #212529;
}

.searchbox {
    border: 1px solid #212529;
    border-radius: 10px;
}

.search-link {
    color: gray !important;
}