.episodes-sidebar-dialoge {
    display: flex;
    justify-content: flex-end;
}

.episodes-sidebar-container {
    height: 100%;
    width: 350px !important;
    background-color: #181818;
    padding: 10px 15px;
}

.episodes-sidebar-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-top: 5px;
}

.episodes-sidebar-container .heading-div {
    display: flex;
    flex: 1;
    align-items: center;
}

.episodes-sidebar-container .close-btn-div {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.episodes-sidebar-container .close-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.sidebar-epiosode-container {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    cursor: pointer;
}

.sidebar-epiosode-container .thumbnail {
    display: flex;
    /* flex: 1; */
    position: relative;
    margin-right: 15px;
}

.sidebar-epiosode-container .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    /* padding-left: 10px; */
}

.sidebar-epiosode-container .episode-bottom-content {
    display: flex;
    flex-direction: row;
}

.sidebar-epiosode-container .hover-play-btn-div {
    position: absolute;
    display: none;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.sidebar-epiosode-container:hover .hover-play-btn-div {
    display: flex;
}

.sidebar-tabpannel .css-19kzrtu {
    padding: 0px !important;
}

.sidebar-epiosode-container .episode-image {
    height: 80px;
}