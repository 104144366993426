:root {
    --color-primary: #000000;
    --color-secondary: #222222;
    --color-tertiary: #303030;
    --color-text: #383838;
    --color-buttons: #f7d23e;

}

.signIn__header {
    font-size: 2rem;
}

.signup__anchor-tag {
    color: var(--color-buttons);
}

.signIn__container {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.699), rgba(8, 8, 8, 0.158), rgba(0, 0, 0, 0.671)), url('../images/custom/landing-landscape.jpg');
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    display: flex;
}

.signIn__container-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(46, 45, 45);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    opacity: 1;
    border-radius: 6px;
    padding: 1rem;

}

.signIn__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.signIn-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    color: var(--color-buttons) !important;
    border: 2px solid var(--color-buttons) !important;


}

.text__feilds {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    justify-content: center;
}

.signIn-box {
    margin-top: 10px;
}

.signin-inner {}

@media (max-width: 600px) {
    .signIn__container-inner {
        width: 85vw;

    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {

        width: 65vw;
    }

}

@media (max-width: 768px) {

    .signIn__container-inner {
        width: 85vw;

    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {

        width: 40vw;
        margin-bottom: 1rem;
    }


}