.css-10wpov9-MuiTypography-root {
    padding: 5px 0px !important;
}

.red-hd {
    color: red;
    margin-left: 5px !important;
}

.quality-wrapper {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}