.full-screen-video-player {
    height: 100vh !important;
    width: 100vw !important;
    position: absolute;
    top: 0;
    object-fit: fill;
}

.full-screen-video-player video {
    object-fit: cover;
}

.full-screen-video-player button {
    display: none;
}

.controlls-container {
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.controlls-container .icon {
    pointer-events: auto;
}

.shown-controlls-container {
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.play-pause-btn-container {
    display: flex;
    flex: 8;
    justify-content: center;
    align-items: center;
}

.play-pause-icon {
    font-size: 80px;
    color: #ffca00;
    cursor: pointer;
}

.slider-container {
    display: flex;
    flex-direction: row;
    padding: 0px 22px;
}

.slider-container .slider {
    flex: 1;
    padding: 8px 20px 0px 20px;
}

.duration-div,
.current-time-div {
    justify-content: center;
    align-items: center;
    display: flex;
}

.all-controlls-container {
    display: flex;
    flex: 1;
}

.all-controlls-container {
    align-items: center;
}

.all-controlls-container .left-controlls {
    display: flex;
    flex: 1;
}

.all-controlls-container .right-controlls {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.slider-container {
    position: relative;
}

.payable-seek-container {
    position: absolute;
    width: 97.5%;
    height: 3px;
    bottom: 14px;
    border-radius: 5px;
}

.placeholder-image {
    height: 100vh;
    width: 100%;
    position: absolute;
}