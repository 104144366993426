.profile-avatar span {
    font-size: 60px;
    font-weight: 500;
}

.profile-avatar {
    margin-bottom: 20px;
}

.clickable-icon {
    cursor: pointer;
}