.publisher-card {
    background-color: rgb(25, 25, 25);
    border-radius: 5px;
}

.publisher-card-header {
    padding: 20px;
}

.publisher-card-body {
    padding: 15px;
}

.dataTables_length {
    margin-bottom: 15px;
}

.dataTables_length label {
    margin-bottom: 15px;
}

.dataTables_length select {
    width: auto;
    height: 30px;
    margin: 0 5px;
    color: #a2a4af;
    border-color: transparent;
    background-color: #363431;
}

.dataTables_length select:focus {
    box-shadow: none;
    border-color: transparent;
    background-color: #363431;
    color: #D1D0CF;
}

.dataTables_filter {
    text-align: right;
    margin-bottom: 15px;
}

.dataTables_filter label {
    margin-bottom: 15px;
}

.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
    height: 30px;
    background-color: #363431;
    border: 1px solid transparent;
    color: #D1D0CF;
    font-size: 16px;
    border-radius: 6px;
}

.dataTables_filter input:focus {
    background-color: #363431;
    color: #D1D0CF;
}

.dataTable {
    margin: 6px 0;
    color: #D1D0CF;
}

.dataTable thead th {
    padding: 0 15px 15px 15px;
    font-size: 14px;
    color: #ffca00;
    line-height: 100%;
    border: none;
    text-transform: uppercase;
    border-bottom: 2px solid #363431;
}

.dataTable tbody tr {
    border-bottom: 10px solid #191919;
    background-color: #363431;
}

.dataTable tbody td {
    text-align: center;
    background-color: transparent;
    border: 0px solid transparent;
    box-sizing: content-box;
    border-top: 1px solid #363431;
    vertical-align: middle;
    padding: .75rem;
}

.dataTables_info {
    padding-top: 20px;
}

.dataTables_paginate {
    text-align: right;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    border-radius: .25rem;
    margin: 15px 0;
}

.page-link {
    border-color: transparent;
    background-color: #363431;
    color: #6c757d !important;
    pointer-events: none;
    cursor: auto;
    padding: .5rem .75rem;
}

.paginate_button:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.paginate_button:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.form_video-upload {
    background: #363431;
    border: 2px #57523D dashed;
}

.form_video-upload_button {
    background: #ffca00;
    border-color: #ffca00;
    color: black;
    font-size: 14px;
    border-radius: 4px !important;
    padding: 6px 12px;
}

.form_video-upload_button:hover {
    background: #f7d23e;
    border-color: #f7d23e;
    color: black;
}

#portraitDiv {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form_publisher-fields label {
    margin-bottom: .5rem;
}

.form_publisher-fields input {
    background-color: #363431;
    border: 1px solid transparent;
    height: 46px;
    color: #D1D0CF;
}

.form_publisher-fields input:disabled {
    background-color: #363431;
}

.form_publisher-fields select {
    background-color: #141414;
    color: #D1D0CF;
    border: 1px solid transparent;
    height: 46px;
    font-size: 16px;
}

.form_publisher-fields textarea {
    background-color: #363431;
    border: 1px solid transparent;
    color: #D1D0CF;
}

.form_publisher-fields input:focus {
    background-color: #363431;
    color: #D1D0CF;
}

.form_publisher-fields select:focus {
    background-color: #363431;
    color: #D1D0CF;
}

.form_publisher-fields textarea:focus {
    background-color: #363431;
    color: #D1D0CF;
}

#landscapeDiv {
    background-image: url('https://api.multitude.io/frontend/images/favorite/ml.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.landscapeDiv{
    background-image: url('https://api.multitude.io/frontend/images/favorite/ml.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.react-datepicker {
    border: 1px solid #ffca00;
    background-color: #3431;
}

.react-datepicker__header {
    background-color: #3431;
    border-bottom: 1px solid #ffca00;
}

.react-datepicker__current-month {
    color: #a2a4af;
}

.react-datepicker__day-name {
    color: #a2a4af;
}

.react-datepicker__month {
    background-color: #363431;
    margin: 0;
    padding: 0.4rem;
}

.react-datepicker__day {
    color: #D1D0CF;
}

.react-datepicker__day:hover {
    color: #000;
    background-color: #D1D0CF;
}

.react-datepicker__day--keyboard-selected {
    background-color:#ffca00;
    color: black;    
}

.react-datepicker__day--selected {
    background-color:#ffca00;
    color: black;
}

.react-datepicker-time__header {
    color: #a2a4af;
}

.react-datepicker__time-container .react-datepicker__time {
    background: #363431;
    color: #D1D0CF;
}

.react-datepicker__time-list-item:hover {
    color: #000;
    background-color: #D1D0CF;
}

.react-datepicker__time-list-item--selected {
    background-color:#ffca00 !important;
    color: black !important;    
}

.form_episode-upload {
    padding: 15px; 
    background-color: #363431;
    color: #D1D0CF;
}

progress { 
    margin-bottom: 16px;
    width: 100%;
    accent-color: #ffca00;
}
