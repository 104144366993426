:root {
    --color-primary: #000000;
    --color-secondary: #222222;
    --color-tertiary: #303030;
    --color-text: #383838;
    --color-buttons: #f7d23e;

}

.dys__container {
    min-height: 60vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--color-primary);
    flex-wrap: wrap;
    padding: 2rem;

}

.dys__content-left {
    color: white;
    display: flex;
    flex-direction: column;

}

.dys__content-left-header {
    font-size: 3vw;
}

.dys__content-text {
    font-size: 1.5vw;
}

.dys__content-right-image {
    width: 30vw;
    object-fit: contain;
}

.independently-original {
    width: 40%;
    height: auto;
}

@media (max-width: 600px) {


    .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .dys__content-right {
        display: flex;
        justify-content: center;
    }

    .dys__content-right-image {
        /* width: 200px; */
        object-fit: contain;
    }

    .dys__container {
        min-height: 60vh;
    }

    .dys__content-left-header {
        font-size: 7vw;
    }

    .dys__content-text {
        font-size: 4vw;
    }
}